<template>
  <div id="data-list-list-view" class="data-list-container">
    <div v-if="isLoading" class="flex w-full h-full p-24">
      <img src="@/assets/images/loading1.gif" alt="" width="50" class="mx-auto">
    </div>
    <div v-show="!isLoading">
      <div class="vx-row">
        <div class="vx-col w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/6 xl:w-1/6">
          <a href="#" class="stat_btn" :class="{'current': filter.status === ''}" @click="filter.status = ''">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="ActivityIcon"
              icon-right
              :statistic="stats.all"
              :statisticTitle="$t('orderPage.all')" />
          </a>
        </div>
        <div class="vx-col w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/6 xl:w-1/6">
          <a href="#" class="stat_btn" :class="{'current': filter.status === 'pending'}" @click="filter.status = 'pending'">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="LoaderIcon"
              icon-right
              :statistic="stats.pending"
              :statisticTitle="$t('orderPage.pending')"
              color="warning" />
          </a>
        </div>
        <div class="vx-col w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/6 xl:w-1/6">
          <a href="#" class="stat_btn" :class="{'current': filter.status === 'accepted'}" @click="filter.status = 'accepted'">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="CheckCircleIcon"
              icon-right
              :statistic="stats.accepted"
              :statisticTitle="$t('orderPage.accepted')"
              color="success" />
          </a>
        </div>
        <div class="vx-col w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/6 xl:w-1/6">
          <a href="#" class="stat_btn" :class="{'current': filter.status === 'rejected'}" @click="filter.status = 'rejected'">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="AlertOctagonIcon"
              icon-right
              :statistic="stats.rejected"
              :statisticTitle="$t('orderPage.rejected')"
              color="danger" />
          </a>
        </div>
        <div class="vx-col w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/6 xl:w-1/6">
          <a href="#" class="stat_btn" :class="{'current': filter.status === 'delivered'}" @click="filter.status = 'delivered'">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="TruckIcon"
              icon-right
              :statistic="stats.delivered"
              :statisticTitle="$t('orderPage.delivered')"
              color="success" />
          </a>
        </div>
        <div class="vx-col w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/6 xl:w-1/6">
          <a href="#" class="stat_btn" :class="{'current': filter.status === 'not_delivered'}" @click="filter.status = 'not_delivered'">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="XOctagonIcon"
              icon-right
              :statistic="stats.not_delivered"
              :statisticTitle="$t('orderPage.notDelivered')"
              color="danger" />
          </a>
        </div>
      </div>
      <vx-card ref="filterCard" :title="$t('orderPage.filter')" class="user-list-filters mb-8" actionButtons @refresh="resetColFilters" @remove="resetColFilters">
        <div class="vx-row">
          <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
            <label class="text-sm opacity-75">{{$t('status')}}</label>
            <v-select :options="statusOptions" :clearable="false" :label="`${$i18n.locale}`" v-model="filter.status" class="mb-4 md:mb-0" :reduce="el => el.value" />
          </div>
          <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
            <label class="text-sm opacity-75">{{$t('type')}}</label>
            <v-select :options="marketOptions" :clearable="false" :label="`${$i18n.locale}`" v-model="filter.delivery_type" class="mb-4 md:mb-0" :reduce="el => el.value" />
          </div>
          <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
            <label class="text-sm opacity-75">{{$t('orderPage.dateFrom')}}</label>
            <datepicker monday-first clear-button :disabledDates="disabledDates" :placeholder="$t('orderPage.selectDate')" v-model="filter.from" @input="setDates($event,'from')" />
          </div>
          <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
            <label class="text-sm opacity-75">{{$t('orderPage.dateTo')}}</label>
            <datepicker monday-first clear-button :disabledDates="disabledDates" :placeholder="$t('orderPage.selectDate')" v-model="filter.until" @input="setDates($event,'to')" />
          </div>
        </div>
      </vx-card>
      <vs-table ref="table" :max-items="filter.limit" :data="filteredOrders"> <!-- multiple v-model="selected"-->
        <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
          <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 items-per-page-handler mr-auto">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentx * filter.limit - (filter.limit - 1) }} - {{ orders.pagination.all - currentx * filter.limit > 0 ? currentx * filter.limit : orders.pagination.all }} of {{ orders.pagination.all }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item v-for="opt in perPageOptions" :key="opt" @click="setLimit(opt)">
                <span>{{ opt }}</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
          <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ $t('tableColumns') }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <ul class="centerx p-5">
                <li v-for="(oKey, idx) in prodColumns" :key="idx" class="py-1">
                  <vs-checkbox v-model="columns" :vs-value="oKey" class="capitalize">{{ $t(`fields.${oKey}`) }}</vs-checkbox>
                </li>
              </ul>
            </vs-dropdown-menu>
          </vs-dropdown>
          <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ $t('orderPage.searchBy') }}: {{ $t(`fields.${searchBy.label}`) }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item v-for="search in searchOptions" :key="search.value" @click="searchBy=search">
                <span>{{ $t(`fields.${search.label}`) }}</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
          <div class="inline-block relative mb-4">
            <vs-input :placeholder="$t('search')" class="custom-search-input" v-model="searchQuery" />
            <feather-icon v-if="searchQuery.length" icon="TrashIcon" svgClasses="w-4 h-4 hover:text-danger stroke-current" style="position: absolute !important; top: 14px; right: 13px" class="ml-2" @click.stop="searchQuery = ''" />
          </div>
        </div>

        <template slot="thead">
          <vs-th v-if="columns.indexOf('id') >= 0" sort-key="name">{{ $t('fields.id') }}</vs-th>
          <vs-th v-if="columns.indexOf('code') >= 0" sort-key="name">{{ $t('fields.code') }}</vs-th>
          <vs-th v-if="columns.indexOf('user_name') >= 0" sort-key="name">{{ $t('fields.name') }}</vs-th>
          <vs-th v-if="columns.indexOf('user_phone') >= 0" sort-key="user_phone">{{ $t('fields.phone') }}</vs-th>
          <vs-th v-if="columns.indexOf('delivery_type') >= 0" sort-key="delivery_type">{{ $t('fields.type') }}</vs-th>
          <!-- <vs-th sort-key="popularity">User Rate</vs-th> -->
          <vs-th v-if="columns.indexOf('address') >= 0" sort-key="address" class="truncate">{{ $t('fields.address') }}</vs-th>
          <vs-th v-if="columns.indexOf('status') >= 0" sort-key="status">{{ $t('fields.status') }}</vs-th>
          <vs-th v-if="columns.indexOf('isSynced') >= 0" sort-key="isSynced">{{ $t('fields.isSynced') }}</vs-th>
          <vs-th v-if="columns.indexOf('total_price') >= 0" sort-key="price">{{ $t('fields.price') }}</vs-th>
          <vs-th v-if="columns.indexOf('delivery_time') >= 0" sort-key="delivery_time">{{ $t('fields.delivery_time') }}</vs-th>
          <vs-th v-if="columns.indexOf('comment_by_user') >= 0" sort-key="comment_by_user">{{ $t('fields.comment_by_user') }}</vs-th>
          <vs-th v-if="columns.indexOf('createdAt') >= 0" sort-key="createdAt">{{ $t('props.createdAt') }}</vs-th>
          <vs-th v-if="columns.indexOf('updatedAt') >= 0" sort-key="updatedAt">{{ $t('props.updatedAt') }}</vs-th>
          <vs-th class="px-0"></vs-th>
          <vs-th>{{ $t('fields.actions') }}</vs-th>
        </template>

          <template slot-scope="{data}">
            <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td  v-if="columns.indexOf('id') >= 0">{{ data[indextr].id }}</vs-td>
                <vs-td v-if="columns.indexOf('code') >= 0">
                  <router-link :to="{name: 'orderShow', params: {id: data[indextr].uuid}}" class="text-dark hover:text-primary">
                    <p class="product-name font-medium truncate">#{{ data[indextr].code }}</p>
                  </router-link>
                </vs-td>
                <vs-td v-if="columns.indexOf('user_name') >= 0"><p class="product-name font-medium truncate">{{ data[indextr].user_name }}</p></vs-td>
                <vs-td v-if="columns.indexOf('user_phone') >= 0"><p class="product-name font-medium truncate">{{ data[indextr].user_phone }}</p></vs-td>
                <vs-td v-if="columns.indexOf('delivery_type') >= 0" class="px-0 mx-0"><vs-chip :color="data[indextr].delivery_type === 'market' ? 'primary' : 'success'" class="product-order-status">{{ $t(data[indextr].delivery_type) }}</vs-chip></vs-td>
                <!-- <vs-td><vs-progress :percent="Number(data[indextr].rating)*100/5" :color="getPopularityColor(Number(data[indextr].rating)*100/5)" class="shadow-md" /></vs-td> -->
                <vs-td v-if="columns.indexOf('address') >= 0"><p class="product-name font-medium truncate" :title="data[indextr].address">{{ data[indextr].address }}</p></vs-td>
                <vs-td v-if="columns.indexOf('status') >= 0" class="px-0 mx-0"><vs-chip :color="getOrderStatusColor(data[indextr].status)" class="product-order-status">{{ $t(`orderPage.${data[indextr].status}`) }}</vs-chip></vs-td>
                <vs-td v-if="columns.indexOf('isSynced') >= 0" :data="data[indextr].isSynced">
                  <vs-chip :color="data[indextr].isSynced ? 'primary' : 'danger'">
                    <feather-icon v-if="data[indextr].isSynced" icon="CheckIcon" svgClasses="w-5 h-5" />
                    <feather-icon v-else icon="XIcon" svgClasses="w-5 h-5" />
                  </vs-chip>
                </vs-td>
                <vs-td v-if="columns.indexOf('total_price') >= 0" class="p-0 ml-0">
                  <div class="product-price font-semibold">
                    <feather-icon v-if="data[indextr].payment_type === 'cart'" icon="CreditCardIcon" svgClasses="mr-2 w-5 h-5 text-primary" />
                    <feather-icon v-else icon="DollarSignIcon" svgClasses="mr-2 w-5 h-5 text-primary" />
                    <span style="vertical-align: top">{{ data[indextr].total_price }}m</span>
                  </div>
                </vs-td>
                <vs-td v-if="columns.indexOf('delivery_time') >= 0"><p class="product-name font-medium">{{ data[indextr].delivery_time }}</p></vs-td>
                <vs-td v-if="columns.indexOf('comment_by_user') >= 0"><p class="product-name font-medium">{{ data[indextr].comment_by_user }}</p></vs-td>
                <vs-td v-if="columns.indexOf('createdAt') >= 0" class="w-16"><p class="product-name font-medium">{{ new Date(data[indextr].createdAt).toLocaleString('ru-RU') }}</p></vs-td>
                <vs-td v-if="columns.indexOf('updatedAt') >= 0" class="w-16"><p class="product-name font-medium">{{ new Date(data[indextr].updatedAt).toLocaleString('ru-RU') }}</p></vs-td>
                <vs-td class="pr-0">
                  <template v-if="data[indextr].status === 'pending'">
                    <feather-icon icon="CheckSquareIcon" svgClasses="w-5 h-5 text-primary" :title="$t('orderPage.accept')" class="mr-1" @click.stop="confirm('accept', tr.code, tr.uuid)" />
                    <feather-icon icon="XSquareIcon" svgClasses="w-5 h-5 text-danger" :title="$t('orderPage.reject')" class="" @click.stop="confirmReject(tr.code, tr.uuid)" />
                  </template>
                  <template v-if="data[indextr].status === 'accepted'">
                    <feather-icon icon="TruckIcon" svgClasses="w-5 h-5 text-primary" :title="$t('orderPage.sendTo')" class="mr-1" @click.stop="sentOrder(tr)" />
                  </template>
                  <template v-if="data[indextr].status === 'sent'">
                    <feather-icon icon="HomeIcon" svgClasses="w-5 h-5 text-primary" :title="$t('orderPage.deliver')" class="mr-1" @click.stop="confirm('deliver', tr.code, tr.uuid)" />
                    <feather-icon icon="XCircleIcon" svgClasses="w-5 h-5 text-danger" :title="$t('orderPage.notDeliver')" class="" @click.stop="confirmNotDeliver(tr.code, tr.uuid)" />
                  </template>
                  <template v-if="data[indextr].status === 'delivered'">
                    <template v-if="data[indextr].rating">
                      <feather-icon v-for="i in Math.floor(data[indextr].rating)" :key="i" icon="StarIcon" svgClasses="w-4 h-4 text-warning" :title="$t('orderPage.deliver')" class="" />
                      <feather-icon v-for="i in 5 - Math.floor(data[indextr].rating)" :key="`f${i}`" icon="StarIcon" svgClasses="w-4 h-4" :title="$t('orderPage.deliver')" class="" />
                    </template>
                    <template v-else>
                      <feather-icon v-for="i in 5" :key="i" icon="StarIcon" svgClasses="w-4 h-4" :title="$t('orderPage.deliver')" class="" />
                    </template>
                  </template>
                </vs-td>

                <vs-td class="whitespace-no-wrap">
                  <router-link :to="{name: 'orderInvoice', params: {id: data[indextr].uuid}}" class="text-dark pr-2">
                    <feather-icon icon="FileTextIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" />
                  </router-link>
                  <router-link :to="{name: 'orderShow', params: {id: data[indextr].uuid}}" class="text-dark">
                    <feather-icon icon="EyeIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" />
                  </router-link>
                  <!-- <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click.stop="editData(tr)" /> -->
                  <!-- <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="deleteData(tr.id)" /> -->
                </vs-td>

              </vs-tr>
            </tbody>
          </template>
      </vs-table>
      <vs-row class="mt-4">
        <vs-pagination :total="pages" :max="9" v-model="currentx" @change="paginateTo"></vs-pagination>
      </vs-row>
      <vs-popup :active.sync="activePrompt" variant="danger" :title="isRejecting ? $t('orderPage.reject') : isNotDelivered ? $t('orderPage.notDeliver') : $t('orderPage.sendTo')">
        <div class="con-exemple-prompt">
          <template v-if="isRejecting || isNotDelivered">
            <p class="mb-3">{{$t('enterReason')}}:</p>
            <vs-textarea v-validate="'required'" name="reason" v-model="note" :placeholder="$t('reason')" />
            <span class="text-danger text-sm" v-show="errors.has('reason')">{{ errors.first('reason') }}</span>
            <vs-row class="mt-6">
              <vs-button @click="rejectOrder" color="danger">{{ isRejecting ? $t('reject') : $t('orderPage.notDeliver') }}</vs-button>
              <vs-button @click="cancel" color="primary" type="border" class="ml-auto">{{$t('cancel')}}</vs-button>
            </vs-row>
          </template>
          <template v-else>
            <v-select v-validate="'required'" label="name" :options="carriersFiltered" name="carrier" :placeholder="$t('selectOne')+' '+$t('courier')" v-model="selectedCarrier" :reduce="carrier => carrier.id" />
            <span class="text-danger text-sm" v-show="errors.has('carrier')">{{ errors.first('carrier') }}</span>
            <!-- {{ carriers }} -->
            <vs-row class="mt-24">
              <vs-button @click="submitSent">{{ $t('sent') }}</vs-button>
              <vs-button @click="cancel" color="danger" type="border" class="ml-auto">{{$t('cancel')}}</vs-button>
            </vs-row>
          </template>
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker'
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'

export default {
  components: { vSelect, Datepicker, StatisticsCardLine },
  data () {
    return {
      searchBy: { label: 'code', value: 'code' },
      orders: { pagination: {}, data: [] },
      columns: this.$route.query.cols || ['code', 'user_name', 'user_phone', 'delivery_type', 'address', 'status', 'total_price', 'createdAt', 'stock_quantity'],
      filter: {
        limit: Number(this.$route.query.limit || 20),
        offset: Number(this.$route.query.limit || 20) * (Number(this.$route.query.page || 1) - 1),
        code: '',
        user_phone:'',
        status: '',
        delivery_type: '',
        from: this.$route.query.from && this.$route.query.from !== 'none' ? new Date(this.$route.query.from) : this.$route.query.from === 'none' ? null : new Date(Date.now() - (86400000 * 2)),
        until: this.$route.query.to && this.$route.query.to !== 'none' ? new Date(this.$route.query.to) : null
      },
      searchOptions: [
        { label: 'phone', value: 'user_phone' },
        { label: 'code', value: 'code' }
      ],
      marketOptions: [
        { tm: 'Ählisi', ru: 'Все', en: 'All', value: '' },
        { tm: 'Market', ru: 'Мазкет', en: 'Market', value: 'market' },
        { tm: 'Ekspres', ru: 'Экспресс', en: 'Express', value: 'express' }
      ],
      statusOptions: [
        { tm: 'Ählisi', ru: 'Все', en: 'All', value: '' },
        { tm: 'Garaşylýar', ru: 'В ожидании', en: 'Pending', value: 'pending' },
        { tm: 'Kabul edilen', ru: 'Принятый', en: 'Aaccepted', value: 'accepted' },
        { tm: 'Ugradylan', ru: 'Отправлен', en: 'Sent', value: 'sent' },
        { tm: 'Ret edilen', ru: 'Отклонен', en: 'Rejected', value: 'rejected' },
        { tm: 'Eltilen', ru: 'Доставлен', en: 'Delivered', value: 'delivered' },
        { tm: 'Eltilmedik', ru: 'Не доставлен', en: 'Not delivered', value: 'not_delivered' }
      ],
      currentx: Number(this.$route.query.page || 1),
      perPageOptions: [10, 15, 20, 50, 100],
      isMounted: false,
      isLoading: true,
      searchQuery: '',
      activeOrder: '',
      carriers: [],
      selectedCarrier: '',
      activePrompt: false,
      isRejecting: '',
      isNotDelivered: '',
      note: '',
      stats: { all: 0, pending: 0, accepted: 0, rejected: 0, delivered: 0, not_delivered: 0 }
    }
  },
  watch: {
    filter: {
      handler () { this.fetchData() },
      deep: true
    },
    searchQuery (newVal) {
      if (newVal.length > 1) { this.filter[this.searchBy.value] = newVal }
      if (!newVal) { this.filter[this.searchBy.value] = '' }
    },
    columns (newVal) {
      const query = { ...this.$route.query, cols: newVal }
      this.$router.replace({ query })
    }
  },
  computed: {
    pages          () { return Math.ceil(this.orders.pagination.all / this.filter.limit) },
    filteredOrders () { return this.orders.data },
    disabledDates  () { return { from: new Date() } },
    carriersFiltered () { return this.activeOrder.delivery_type === 'express' ? this.carriers.filter(e => e.isExpress) : this.carriers.filter(e => !e.isExpress) },
    prodColumns () {
      const cols = ['uuid', 'payment_type', 'delivery_code', 'delivery_cost', 'rating', 'actionAt', 'note', 'carrierId', 'userId', 'ownerId']
      return this.orders.data[0] ? Object.keys(this.orders.data[0]).filter((col) => !cols.includes(col)) : []
    }
  },
  methods: {
    setDates (e, type) {
      const query = { ...this.$route.query }
      if (e) { query[type] = e.toISOString() } else { query[type] = 'none' }
      this.$router.replace({ query })
    },
    sentOrder (id) {
      this.activeOrder = id
      this.activePrompt = true
      this.isRejecting = false
      this.isNotDelivered = false
    },
    submitSent () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$http.patch(`/orders/edit/${this.activeOrder.uuid}`, {
            status: 'sent',
            carrierId: this.selectedCarrier
          }).then(() => {
            this.fetchData()
            this.activePrompt = false
            this.activeOrder = ''
            this.isRejecting = ''
            this.notify('success', this.$t('notify.success'), `${this.$t('order')} ${this.$t('notify.sSent')}`)
          }).catch(() => {
            this.notify('error', this.$t('notify.error'), this.$t('notify.errOccured'))
          })
        }
      })
    },
    removeRefreshAnimation () {},
    confirmReject (code, id) {
      this.activeOrder = id
      this.isRejecting = code
      this.activePrompt = true
    },
    confirmNotDeliver (code, id) {
      this.activeOrder = id
      this.isNotDelivered = code
      this.activePrompt = true
    },
    confirm (e, code, uuid) {
      this.activeOrder = uuid
      const color = e === 'reject' || e === 'not_delivered' ? 'danger' : 'primary'
      let title = ''
      let text = ''
      let accept = ''
      let acceptText = ''
      const cancelText = this.$t('cancel')
      if (e === 'accept') {
        title = this.$t('orderPage.confirmAccept')
        text = `${this.$t('orderPage.acceptingOrder')} ${code}`
        accept = this.acceptOrder
        acceptText = this.$t('orderPage.accept')
      }
      if (e === 'deliver') {
        title = this.$t('orderPage.confirmDeliver')
        text = `${this.$t('orderPage.deliveringOrder')} ${code}`
        accept = this.deliverOrder
        acceptText = this.$t('submit')
      }
      this.$vs.dialog({ type: 'confirm', color, title, text, accept, acceptText, cancelText })
    },
    acceptOrder () {
      this.$http.patch(`/orders/edit/${this.activeOrder}`, { status: 'accepted' })
        .then(() => {
          this.fetchData()
          this.notify('success', this.$t('notify.success'), `${this.$t('order')} ${this.$t('notify.sAccepted')}`)
        })
        .catch(() => {
          this.notify('error', this.$t('notify.error'), this.$t('notify.errOccured'))
        })
    },
    deliverOrder () {
      this.$http.patch(`/orders/edit/${this.activeOrder}`, { status: 'delivered' })
        .then(() => {
          this.fetchData()
          this.notify('success', this.$t('notify.success'), `${this.$t('order')} ${this.$t('notify.sDelivered')}`)
        })
        .catch(() => {
          this.notify('error', this.$t('notify.error'), this.$t('notify.errOccured'))
        })
    },
    rejectOrder () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$http.patch(`/orders/edit/${this.activeOrder}`, {
            status: this.isRejecting ? 'rejected' : 'not_delivered',
            note: this.note
          }).then(() => {
            this.fetchData()
            this.activePrompt = false
            this.activeOrder = ''
            this.isRejecting = ''
            this.note = ''
            this.notify('success', this.$t('notify.success'), this.isRejecting ? `${this.$t('order')} ${this.$t('notify.sReject')}` : `${this.$t('order')} ${this.$t('notify.sNotDelivered')}`)
          }).catch(() => {
            this.notify('error', this.$t('notify.error'), this.$t('notify.errOccured'))
          })
        }
      })
    },
    cancel () {
      this.activePrompt = false
      this.activeOrder = ''
      this.isRejecting = ''
    },
    resetColFilters () {
      this.filter = {
        limit: 15,
        offset: 0,
        code: '',
        user_phone:'',
        status: '',
        delivery_type: '',
        from: null,
        until: null
      }
      this.$refs.filterCard.removeRefreshAnimation()
    },
    paginateTo (e) {
      if (this.isMounted) {
        this.filter.offset = this.filter.limit * (this.currentx - 1)
        const query = { ...this.$route.query, page: e }
        this.$router.replace({ query })
      }
    },
    setLimit (e) {
      if (this.filter.limit !== e) {
        this.currentx = 1
        this.filter.offset = 0
        this.filter.limit = e
        const query = { ...this.$route.query, limit: e }
        this.$router.replace({ query })
      }
    },
    async fetchData () {
      await this.$http.get('/orders', { params: this.filter }).then(res => {
        this.orders = res.data
        this.fetchStats()
      }).catch(() => {
        this.notify('error', this.$t('notify.error'), this.$t('notify.errOccured'))
      })
    },
    async fetchCarriers () {
      await this.$http.get('/carriers')
        .then(res => { this.carriers = res.data.data })
        .catch(() => {
          this.notify('error', this.$t('notify.error'), this.$t('notify.errOccured'))
        })
    },
    async fetchStats () {
      await this.$http.get('/statistics/current', { params: { model: 0 } })
        .then(res => {
          this.stats = res.data.today
          this.$store.dispatch('setPending', res.data.this_week.pending)
        })
        .catch(() => {
          this.notify('error', this.$t('notify.error'), this.$t('notify.errOccured'))
        })
    },
    deleteData (id) { this.$store.dispatch('dataList/removeItem', id).catch(err => { return (err) }) },
    getOrderStatusColor (status) {
      if (status === 'pending')   return 'warning'
      if (status === 'accepted') return 'primary'
      if (status === 'delivered') return 'success'
      if (status === 'rejected') return 'danger'
      if (status === 'not_delivered')  return 'danger'
      return 'primary'
    },
    getPopularityColor (num) {
      if (num > 80)  return 'success'
      if (num > 60)  return 'primary'
      if (num >= 50) return 'warning'
      if (num < 50)  return 'danger'
      return 'primary'
    },
    notify (type, title, text) {
      this.$vs.notify({
        title,
        text,
        iconPack: 'feather',
        icon: type === 'success' ? 'icon-check-circle' : 'icon-alert-circle',
        color: type === 'success' ? 'primary' : 'danger'
      })
    }
  },
  async created () {
    await this.fetchData()
    await this.fetchCarriers()
    // await this.fetchStats()
    this.isMounted = true
    this.isLoading = false
  }
}
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr{
          box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
          td{
            padding: 20px;
            &:first-child{
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }
            &:last-child{
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }
          }
          td.td-check{
            padding: 20px !important;
          }
      }
    }

    .vs-table--thead{
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text{
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check{
        padding: 0 15px !important;
      }
      tr{
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
  .stat_btn {
    color: #626262;
    .vx-card {
      border: 1px solid transparent;
    }
    &:hover {
      cursor: pointer;
      color: #7367F0;
      .vx-card {
        border: 1px solid #666666;
      }
    }
    &:active {
      .vx-card {
        border: 1px solid green;
      }
    }
    &.current {
      color: #7367F0;
      font-weight: 500;
      .vx-card {
        border: 1px solid #7367F0;
      }
    }
  }
  .custom-search-input {
    .vs-con-input {
      input {
        padding: 13px !important;
        border-radius: 50px;
      }
    }
  }
}
.vdp-datepicker {
  // input {
  //   padding: 13.5px 16px;
  //   border-radius: 25px;
  //   width: 130px;
  // }
  .vdp-datepicker__clear-button {
    position: absolute;
    right: 10px;
    top: 0px;
    font-size: 24px;
  }
}
</style>
